/** 统一处理 Cookie */
import Cookies from "js-cookie";
let CacheKey = {
  TOKEN: "qicheng-pc-token",
  USER_ID: "qicheng-pc-user-id",
  USER: "qicheng-pc-user",
};
export const getToken = () => {
  return Cookies.get(CacheKey.TOKEN);
};
export const setToken = (token) => {
  Cookies.set(CacheKey.TOKEN, token);
};
export const removeToken = () => {
  Cookies.remove(CacheKey.TOKEN);
};

// 用户id
export const getUserId = () => {
  return Cookies.get(CacheKey.USER_ID);
};
export const setUserId = (id) => {
  Cookies.set(CacheKey.USER_ID, id);
};
export const removeUserId = () => {
  Cookies.remove(CacheKey.USER_ID);
};

// 用户
export const getUser = () => {
  return Cookies.get(CacheKey.USER);
};
export const setUser = (user) => {
  Cookies.set(CacheKey.USER, user);
};
export const removeUser = () => {
  Cookies.remove(CacheKey.USER);
};
