import { request } from "@/utils/axios";

// 获取首页楼层装修数据
export function indexData() {
  return request({
    url: "/indexInfo/getIndexSwiper",
    method: "get",
  });
}

// 获取首页banner
export function getIndexSwiper() {
  return request({
    url: "/indexInfo/getIndexSwiper",
    method: "get",
  });
}

// 获取首页品牌
export function getBrandList(params) {
  return request({
    url: "/indexInfo/getBrandList",
    method: "get",
    params,
  });
}

// 获取推荐词
export function getRecommendedWord() {
  return request({
    url: "/indexInfo/getRecommendedWord",
    method: "get",
  });
}

// 获取公告通知
export function getIndexNotice(params) {
  return request({
    url: "/indexInfo/getIndexNotice",
    method: "get",
    params,
  });
}

// 获取资讯
export function getArticleAndEbookList(params) {
  return request({
    url: "/indexInfo/getArticleAndEbookList",
    method: "get",
    params,
  });
}
// 获取文章详情
export function getArticleInfo(params) {
  return request({
    url: "/indexInfo/getArticleInfo",
    method: "get",
    params,
  });
}
// 获取商品推荐
export function getRecommendGoodsData(params) {
  return request({
    url: "/indexInfo/getRecommendGoodsData",
    method: "get",
    params,
  });
}
// 获取分类推荐商品列表
export function getRecommendClassGoodslist(params) {
  return request({
    url: "/indexInfo/getRecommendClassGoodslist",
    method: "get",
    params,
  });
}
// 获取分类
export function getAllCategoryData(params) {
  return request({
    url: "/indexInfo/getAllCategoryData",
    method: "get",
    params,
  });
}

// 用户创建采购清单
export function userCreatPurchaseOrderName(data) {
  return request({
    url: "/userPurchaseOrder/userCreatPurchaseOrderName",
    method: "post",
    data,
  });
}

// 获取用户采购清单目录数据
export function getUserPurchaseOrderName(params) {
  return request({
    url: "userPurchaseOrder/getUserPurchaseOrderName",
    method: "get",
    params,
  });
}
// 获取采购清单商品列表
export function getUserPurchaseOrderList(params) {
  return request({
    url: "userPurchaseOrder/getUserPurchaseOrderList",
    method: "get",
    params,
  });
}
// 删除采购清单里的商品
export function removeUserPurchaseOrderGoods(data) {
  return request({
    url: "/userPurchaseOrder/removeUserPurchaseOrderGoods",
    method: "post",
    data,
  });
}
// 首页搜索
export function userSearchGoods(params) {
  return request({
    url: "userGetGoodsList/userSearchGoods",
    method: "get",
    params,
  });
}
// 商品条件查询
export function userFindGoodsList(params) {
  return request({
    url: "userGoodsList/userFindGoodsList",
    method: "get",
    params,
  });
}
// 获取商品列表信息
export function getGoodsList(params) {
  return request({
    url: "userGetGoodsList/getGoodsList",
    method: "get",
    params,
  });
}
// 首页搜索和商品列表条件筛选
export function userSearchAndFindGoods(params) {
  return request({
    url: "userGetGoodsList/userSearchAndFindGoods",
    method: "get",
    params,
  });
}
// 把商品加入购物车
export function addGoodsToShopingCart(data) {
  return request({
    url: "/userShoppingCart/addGoodsToShopingCart",
    method: "post",
    data,
  });
}
// 添加商品到采购清单目录
export function userAddPurchaseOrder(data) {
  return request({
    url: "/userPurchaseOrder/userAddPurchaseOrder",
    method: "post",
    data,
  });
}
// 获取用户的购物车数据
export function getAllShoppingCartList(params) {
  return request({
    url: "/userShoppingCart/getAllShoppingCartList",
    method: "get",
    params,
  });
}
// 删除购物车商品
export function deteleShoppingCartList(data) {
  return request({
    url: "userShoppingCart/removeGoodsToShopingCart",
    method: "post",
    data,
  });
}

// 问题意见反馈
export function setUserQuestion(data) {
  return request({
    url: "/indexInfo/setUserQuestion",
    method: "post",
    data,
  });
}

// 用户创建订单
export function userCreatOrder(data) {
  return request({
    url: "/userPay/userCreatOrder",
    method: "post",
    data,
  });
}
// 用户查看优惠券列表
export function getUserCoupon(params) {
  return request({
    url: "/userCoupon/getUserCoupon",
    method: "get",
    params,
  });
}

// 用户领取优惠券
export function userGetCoupon(data) {
  return request({
    url: "/userCoupon/userGetCoupon",
    method: "post",
    data,
  });
}
