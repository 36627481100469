<template>
  <div>
    <div class="crumb" :style="{ width: w }">
      {{ text }}<span class="crumb-item">{{ active }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    active: {
      type: String,
      default: "",
    },
    w: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.crumb {
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  color: #999999;
  line-height: 19px;

  .crumb-item {
    color: #333333;
  }
}
</style>
