<template>
  <div class="layout-content wrap-brand">
    <div class="list">
      <div v-for="item in list" :key="item.id" class="item">
        <img class="img" :src="item.brandimageurl" alt="" />
      </div>
    </div>
    <div @click="goBrand" class="more f-e-e">查看所有品牌 ></div>
  </div>
</template>

<script>
import { getBrandList } from "@/api/index";

export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getBrand();
  },
  methods: {
    getBrand() {
      getBrandList({ pages: 1 }).then((res) => {
        this.list = res.list.data;
      });
    },
    goBrand() {
      this.$router.push({
        path: "/brand",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-brand {
  // height: 232px;
  background: #fff;
  margin-top: 10px;
  padding: 30px 17px 17px 17px;
  box-sizing: border-box;

  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .item {
    width: 182px;
    height: 70px;
    position: relative;
    border-radius: 4px;
    background: #f7f8fb;
    margin-right: 10px;
    margin-top: 10px;
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover {
      transform: translate3d(0, -2px, 0);
      box-shadow: 0 4px 8px 0 rgba(0, 3, 20, 0.05);
    }
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 112px;
    margin: auto;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .more {
    font-size: 12px;
    color: var(--color-primary);
    cursor: pointer;
  }
}
</style>
