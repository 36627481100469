// 全局组件配置
import ComHeader from "@/components/ComHeader"; // 头部
import ComFooter from "@/components/ComFooter"; // footer
import ComNavBar from "@/components/ComNavBar"; // 空状态组件
import ComBtn from "@/components/ComBtn"; // 按钮
import ComProduct from "@/components/ComProduct"; // 商品
import ComCrumb from "@/components/ComCrumb"; // 商品

// import empty from './empty/Main' // 空状态组件
// import drawer from './drawer/Main' // 右侧bar
// import Header from '@/components/header/Header'; // 头部组件
// import FixedTopPage from '@/components/advertising/FixedTop'; // 顶部广告
// import Footer from '@/components/footer/Footer'; // 底部栏
// import Search from '@/components/Search' // 搜索框
// import card from '@/components/card' // 个人中心 卡片
// import cateNav from '@/components/nav/CateNav' // 个人中心 卡片

ComHeader.install = function (Vue) {
  Vue.component("ComHeader", ComHeader);
};

ComFooter.install = function (Vue) {
  Vue.component("ComFooter", ComFooter);
};

ComNavBar.install = function (Vue) {
  Vue.component("ComNavBar", ComNavBar);
};

ComBtn.install = function (Vue) {
  Vue.component("ComBtn", ComBtn);
};

ComProduct.install = function (Vue) {
  Vue.component("ComProduct", ComProduct);
};

ComCrumb.install = function (Vue) {
  Vue.component("ComCrumb", ComCrumb);
};

// FixedTopPage.install = function (Vue) {
//   Vue.component('FixedTopPage', FixedTopPage);
// };

// Footer.install = function (Vue) {
//   Vue.component('BaseFooter', Footer);
// };

// Search.install = function (Vue) {
//   Vue.component('Search', Search);
// };

// card.install = function (Vue) {
//   Vue.component('card', card)
// }

// cateNav.install = function (Vue) {
//   Vue.component('cateNav', cateNav)
// }

// 引用本js中所有的组件
export function InstallAll(Vue) {
  Vue.use(ComHeader);
  Vue.use(ComFooter);
  Vue.use(ComNavBar);
  Vue.use(ComBtn);
  Vue.use(ComProduct);
  Vue.use(ComCrumb);
  // Vue.use(Footer)
  // Vue.use(Search)
  // Vue.use(card)
  // Vue.use(cateNav)
}
