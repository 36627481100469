import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.scss";
import Clipboard from "v-clipboard";
import { InstallAll } from "@/utils/global.js";
import {
  Carousel,
  CarouselItem,
  Dialog,
  Form,
  FormItem,
  Upload,
  Message,
  Input,
  InputNumber,
  Cascader,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Pagination,
  Timeline,
  TimelineItem,
  Table,
  TableColumn,
  Select,
  Option,
  OptionGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Collapse,
  CollapseItem,
  Button,
  Link,
  Tag,
  DatePicker,
  Badge,
  MessageBox,
  Empty,
  Tabs,
  TabPane,
} from "element-ui";
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./styles/element-variables.scss";
const { title } = require("@/config");

Vue.config.productionTip = false;
Vue.use(Select);
Vue.use(TableColumn);
Vue.use(Table);
Vue.use(Pagination);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Cascader);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Dialog);
Vue.use(Carousel);
Vue.use(Empty);
Vue.use(CarouselItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(InputNumber);
Vue.use(Button);
Vue.use(Link);
Vue.use(Tag);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(DatePicker);
Vue.use(Badge);
Vue.use(Tabs);
Vue.use(TabPane);

// Vue.use(ElementUI);
Vue.use(InstallAll);

Vue.prototype.$message = function (msg) {
  Message({ ...msg, duration: 3000 });
};

Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 3000,
    offset: 300,
  });
};

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.use(Clipboard);
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title === undefined ? title : to.meta.title;
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
