<template>
  <div class="wrap-category">
    <div class="cate-box f-c">
      <div class="left">
        <div class="cate-list">
          <div
            v-for="item in options"
            :key="item.id"
            class="item"
            @mouseover="debouncedHandleMouseOver(item)"
            @click="classification(item.id, 1)"
          >
            <div class="title">{{ item.categoryname }}</div>
            <div style="display: flex; align-items: center; flex-wrap: wrap">
              <div
                v-for="child in item.childrenData"
                :key="child.id"
                class="subtitle"
                @click="classification(item.id, 2)"
              >
                {{ child.categoryname }}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-two">
            <div
              v-for="item in twoData.childrenData"
              :key="item.id"
              class="right-title"
              @click="classification(item.id, 2)"
              @mouseover="debouncedHandleMouseOverTwo(item)"
            >
              {{ item.categoryname }}
            </div>
          </div>
          <div class="list">
            <div
              v-for="item in twoDataTwo.childrenData"
              :key="item.id"
              class="sub-item f-c"
              @click="classification(item.id, 3)"
            >
              <img :src="item.categoryimage" alt="" />
              <p>{{ item.categoryname }}s</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllCategoryData } from "@/api/index";
import { debounce } from "lodash";

export default {
  components: {},
  data() {
    return {
      twoData: {},
      twoDataTwo: {},
      options: [],

      levelOneMenu: [
        {
          id: 1,
          name: "手机",
          subtitle: "手机手机手机手机",
        },
        {
          id: 2,
          name: "电脑",
          subtitle: "电脑电脑电脑",
        },
      ],
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    classification(id, index) {
      const url = this.$router.resolve({
        path: "/goodsCategory",
        query: {
          whocategory: index,
          categoryid: id,
        },
      }).href;

      window.open(url, "_blank");
    },
    handleMouseOver(item) {
      if (this.twoData.id !== item.id) {
        this.twoData = item;
      }
    },
    handleMouseOverTwo(item) {
      console.log(item, "我是item");
      if (this.twoDataTwo.id !== item.id) {
        this.twoDataTwo = item;
      }
    },
    debouncedHandleMouseOver: debounce(function (item) {
      this.handleMouseOver(item);
    }, 300),
    debouncedHandleMouseOverTwo: debounce(function (item) {
      this.handleMouseOverTwo(item);
    }, 300),
    transformToCascaderOptions(data) {
      return data.map((item) => {
        return {
          ...item,
          value: String(item.id),
          label: item.categoryname,
          image: item.categoryimage, // 如果需要在级联选择器中使用图片，可以添加这个属性
          children: item.childrenData
            ? this.transformToCascaderOptions(item.childrenData)
            : undefined,
        };
      });
    },
    getCategory() {
      getAllCategoryData().then((res) => {
        this.options = this.transformToCascaderOptions(res.list);
        console.log(this.options);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-category {
  width: 210px;
  height: 474px;
  background: #ffffff;
  .cate-box {
    height: 474px;
    background: #ffffff;
    bottom: -479px;
    left: -2px;
    visibility: visible;
    z-index: 99;

    .left {
      width: 210px;
      height: 474px;
      padding-top: 10px;
      position: relative;
      box-sizing: border-box;
      &:hover .right {
        display: block;
        display: flex;
      }

      .cate-list {
        height: 460px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .title {
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        margin-bottom: 2px;
      }

      .subtitle {
        font-size: 12px;
        color: #999999;
        line-height: 16px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          color: #eb5c20;
        }
      }

      .item {
        width: 210px;
        padding-left: 20px;
        padding-top: 6px;
        box-sizing: border-box;

        &:hover {
          background: rgba(153, 153, 153, 0.2);
        }
      }
    }

    .right {
      right: -770px;
      top: -2px;
      display: none;
      position: absolute;
      box-sizing: border-box;
      width: 770px;
      height: 478px;
      background: #ffffff;
      box-shadow: -1px 0px 4px 0px rgba(153, 153, 153, 0.5);
      z-index: 999;
      .title {
        font-weight: bold;
        font-size: 14px;
        color: var(--color-primary);
        line-height: 19px;
        margin-bottom: 18px;
      }

      .sub-item {
        width: 71px;
        height: 100px;
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        text-align: left;
        margin-bottom: 8px;
        margin-top: 10px;
        display: inline-flex;
        flex-direction: column;
        &:hover {
          color: var(--color-primary);
        }
        img {
          width: 52px;
          height: 52px;
        }
        p {
          margin-top: 5px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0;
          text-align: center;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .right-two {
        width: 150px;
        height: 474px;
        border-right: 1px solid #ddd;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .right-title {
        text-align: center;
        width: 150px;
        height: 50px;
        line-height: 35px;
        font-size: 14px;
        color: #444444;
        padding-top: 6px;
        box-sizing: border-box;
        &:hover {
          background: rgba(153, 153, 153, 0.2);
        }
      }
      .list {
        width: 620px;
        height: 478px;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
