<template>
  <div class="wrap-header">
    <div v-if="announcement" class="notice">
      <div class="layout-content f-c-b notice-content">
        <div class="left f-c">
          <img class="icon-msg" src="@/assets/img/msg.png" alt="" />
          通知
        </div>
        <div class="notice-bar-container">
          <div class="notice-bar__wrap">
            <div
              v-for="(item, index) in noticeList"
              :key="index"
              class="notice-bar__wrap_text"
            >
              {{ item.noticetext }}
            </div>
          </div>
        </div>
        <img
          @click="closeNotice"
          class="icon-close"
          src="@/assets/img/close.png"
          alt=""
        />
      </div>
    </div>

    <div class="bar layout-content f-c-b">
      <div class="left f-c-c">
        您好，欢迎光临一启铖工品！
        <template v-if="token">
          <span @click="goPath('/user/info')" class="text-active login">
            {{ user.username }}
          </span>
          <div class="line">｜</div>
          <span @click="loginOut" class="text-active"> 退出登录 </span>
        </template>
        <template v-else>
          <span @click="goPath('/login')" class="text-active login">登录</span>
          <div class="line">｜</div>
          <span @click="goPath('/register')" class="text-active">注册</span>
        </template>
      </div>
      <div class="right f-c-c">
        <el-dropdown>
          <span class="my">
            我的一启铖<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>黄金糕</el-dropdown-item>
            <el-dropdown-item>狮子头</el-dropdown-item>
            <el-dropdown-item>螺蛳粉</el-dropdown-item>
            <el-dropdown-item disabled>双皮奶</el-dropdown-item>
            <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="line">｜</div>
        关于我们
        <div class="line">｜</div>
        400-999-9999
      </div>
    </div>

    <div class="layout-content search f-c">
      <img
        @click="goIndex"
        class="img-logo"
        src="@/assets/img/logo.png"
        alt=""
      />
      <div class="middle">
        <div class="item-input">
          <div class="input-wrap f-c-c">
            <input
              class="inp"
              type="text"
              v-model="keyword"
              placeholder="请输入产品名称/品牌/型号"
            />
            <com-btn @click="goList" class="btn-search">搜索</com-btn>
          </div>
          <div class="recommend">
            推荐词:
            <span
              @click="selectKey(item)"
              class="item"
              v-for="item in recommendList"
              :key="item.id"
            >
              {{ item.recommendtext }}
            </span>
          </div>
        </div>
        <com-btn @click="goNeedPath('Quote')" type="light" class="btn-quick">
          快速报价
        </com-btn>
        <com-btn
          id="cart-num"
          @click="goNeedPath('Cart')"
          plain
          class="btn-cart"
        >
          购物车
        </com-btn>
        <!-- <el-badge :max="99" type="primary" :value="cartNum" class="item">
          <com-btn
            id="cart-num"
            @click="goNeedPath('Cart')"
            plain
            class="btn-cart"
          >
            购物车
          </com-btn>
        </el-badge> -->
      </div>
      <div class="right">
        <img class="img-qr" src="@/assets/img/index-qr.png" alt="" />
        企业微信 专人对接
      </div>
    </div>

    <div class="layout-content cate-bar f-c">
      <div class="cate-menu f-c-c">
        <img class="icon-cate" src="@/assets/img/cate.png" alt="" />
        全部商品分类
        <div class="cate-box f-c">
          <div class="left">
            <div class="cate-list">
              <div
                v-for="item in options"
                :key="item.id"
                class="item"
                @mouseover="debouncedHandleMouseOver(item)"
                @click="classification(item.id, 1)"
              >
                <div class="title">{{ item.categoryname }}</div>
                <div
                  style="display: flex; align-items: center; flex-wrap: wrap"
                >
                  <div
                    v-for="child in item.childrenData"
                    :key="child.id"
                    @click="classification(item.id, 2)"
                    class="subtitle"
                  >
                    {{ child.categoryname }}
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="right-two">
                <div
                  v-for="item in twoData.childrenData"
                  :key="item.id"
                  class="right-title"
                  @click="classification(item.id, 2)"
                  @mouseover="debouncedHandleMouseOverTwo(item)"
                >
                  {{ item.categoryname }}
                </div>
              </div>
              <div class="list">
                <div
                  v-for="item in twoDataTwo.childrenData"
                  :key="item.id"
                  @click="classification(item.id, 3)"
                  class="sub-item f-c"
                >
                  <img :src="item.categoryimage" alt="" />
                  <p>{{ item.categoryname }}s</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="item in menuList"
        :key="item.name"
        @click="goPathList(item.path, item.query)"
        class="menu-item f-c-c"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRecommendedWord,
  getIndexNotice,
  getAllCategoryData,
} from "@/api/index";
import { debounce } from "lodash";
import { getToken, getUser, removeToken, removeUser } from "@/utils/cookies";
export default {
  components: {},
  computed: {
    cartNum() {
      return this.$store.state.cartNum;
    },
  },
  data() {
    return {
      twoData: {},
      twoDataTwo: {},
      token: "",
      user: {},
      notice: {},
      noticeList: [],
      announcement: false,
      recommendList: [],
      keyword: "",
      options: [],
      menuList: [
        {
          name: "品牌选型",
          path: "/brand",
        },
        {
          name: "案例库",
          path: "",
        },
        {
          name: "电子书",
          path: "/news/info",
          query: { type: 2 },
        },
        {
          name: "供应商合作",
          path: "",
        },
        {
          name: "会员优惠券",
          path: "/coupon",
        },
      ],
    };
  },
  mounted() {
    this.getCategory();
    this.getRecommendList();
    this.token = getToken();
    if (this.token) {
      this.user = JSON.parse(getUser());
    }
    let notice = window.sessionStorage.getItem("pc-notice");
    if (!notice) {
      this.getNotice();
    } else {
      this.notice = {};
    }
  },
  methods: {
    goPathList(path, query) {
      if (path == "") return;
      this.$router.push({
        path,
        query,
      });
    },
    classification(id, index) {
      const url = this.$router.resolve({
        path: "/goodsCategory",
        query: {
          whocategory: index,
          categoryid: id,
        },
      }).href;

      window.open(url, "_blank");
    },
    handleMouseOver(item) {
      if (this.twoData.id !== item.id) {
        this.twoData = item;
      }
    },
    handleMouseOverTwo(item) {
      console.log(item, "我是item");
      if (this.twoDataTwo.id !== item.id) {
        this.twoDataTwo = item;
      }
    },
    debouncedHandleMouseOver: debounce(function (item) {
      this.handleMouseOver(item);
    }, 300),
    debouncedHandleMouseOverTwo: debounce(function (item) {
      this.handleMouseOverTwo(item);
    }, 300),
    transformToCascaderOptions(data) {
      return data.map((item) => {
        return {
          ...item,
          value: String(item.id),
          label: item.categoryname,
          image: item.categoryimage, // 如果需要在级联选择器中使用图片，可以添加这个属性
          children: item.childrenData
            ? this.transformToCascaderOptions(item.childrenData)
            : undefined,
        };
      });
    },
    getCategory() {
      getAllCategoryData().then((res) => {
        this.options = this.transformToCascaderOptions(res.list);
        console.log(this.options);
      });
    },
    getRecommendList() {
      getRecommendedWord().then((res) => {
        this.recommendList = res.list.data;
      });
    },
    getNotice() {
      getIndexNotice().then((res) => {
        // 筛选出 noticestatus 等于 1 的项
        const filteredData = res.list.data.filter(
          (item) => item.noticestatus === 1
        );
        if (filteredData.length > 0) {
          this.announcement = true;
        } else {
          this.announcement = false;
        }

        this.noticeList = filteredData;
      });
    },
    closeNotice() {
      this.notice = {};
      this.announcement = false;
      window.sessionStorage.setItem("pc-notice", 1);
    },
    ifLogin() {
      this.$router.push({
        path: "/login",
      });
      return false;
    },
    goPath(path) {
      this.$router.push({
        path,
      });
    },
    loginOut() {
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeUser();
          removeToken();
          window.location.reload();
        })
        .catch(() => {});
    },
    goIndex() {
      this.$router.push({
        path: "/",
      });
    },
    goNeedPath(name) {
      // if (this.ifLogin()) return;
      this.$router.push({
        name,
      });
    },
    selectKey(value) {
      this.keyword = value.recommendtext;
      this.goList();
    },
    goList() {
      this.$router.push({
        path: "/goodsCategory",
        query: {
          keyword: this.keyword,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-header {
  border-bottom: 3px solid var(--color-primary);
  width: 100%;
  background: #fff;

  .notice {
    width: 100%;
    height: 32px;
    background: #fbd9cd;
    font-family: MicrosoftYaHeiLight;
    font-size: 12px;
    color: #370816;

    .notice-content {
      height: 100%;
      padding: 0 12px 0 20px;
      box-sizing: border-box;
    }
    .notice-bar-container {
      display: flex;
      width: calc(100% - 30px);
      height: 20px;
      overflow: hidden;
      margin-left: 5px;
    }
    .notice-img {
      width: 20px;
      height: 20px;
    }
    .notice-bar__wrap {
      margin-left: 10px;
      display: flex;
      animation: move 20s linear infinite;
      line-height: 20px;
      color: black;
      .notice-bar__wrap_text {
        width: max-content;
        min-width: 100px;
      }
    }
    @keyframes move {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    .left {
      width: 50px;
      margin-right: 30px;
    }

    .middle {
      flex: 1;
    }

    .point {
      width: 3px;
      height: 3px;
      background: #370816;
      border-radius: 50%;
      margin-right: 7px;
    }

    .icon-msg {
      width: 12px;
      height: 10px;
      margin-right: 4px;
    }

    .icon-close {
      width: 14px;
      height: 14px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .bar {
    height: 34px;
    font-size: 12px;
    color: #444444;
    padding: 0 12px 0 20px;
    box-sizing: border-box;

    .line {
      color: #dddddd;
      margin: 0 13px;
    }

    .login {
      margin-left: 9px;
    }

    .text-active {
      color: var(--color-primary);
      cursor: pointer;
    }

    .my {
      font-size: 12px;
      color: #444444;
    }
  }

  .search {
    margin-top: 14px;
    padding-left: 20px;
    .img-logo {
      height: 80px;
      width: 270px;
      cursor: pointer;
    }

    .middle {
      display: flex;
    }

    .input-wrap {
      width: 545px;
      height: 40px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid var(--color-primary);
      box-sizing: border-box;
      overflow: hidden;
    }

    .inp {
      border: none;
      height: 40px;
      flex: 1;
      padding-left: 5px;
      &::placeholder {
        font-size: 12px;
        color: #999999;
      }
    }

    .btn-search {
      width: 93px;
      height: 40px;
    }

    .recommend {
      margin-top: 4px;
      font-size: 12px;
      color: #444444;

      .item {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .btn-quick {
      margin-left: 30px;
      margin-right: 12px;
    }

    .right {
      width: 100px;
      font-size: 12px;
      color: #444444;
      text-align: center;
      margin-left: 30px;
      padding-top: 10px;
    }

    .img-qr {
      width: 56px;
      height: 56px;
      margin: 0 auto;
      margin-bottom: 4px;
    }
  }
  .right-two {
    width: 150px;
    height: 474px;
    border-right: 1px solid #ddd;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .right-title {
    text-align: center;
    width: 150px;
    height: 50px;
    line-height: 35px;
    font-size: 14px;
    color: #444444;
    padding-top: 6px;
    box-sizing: border-box;
    &:hover {
      background: rgba(153, 153, 153, 0.2);
    }
  }
  .cate-bar {
    margin-top: 14px;

    .icon-cate {
      width: 17px;
      height: 14px;
      margin-right: 7px;
    }

    .cate-menu {
      width: 210px;
      height: 42px;
      background: var(--color-primary);
      color: #fff;
      font-size: 18px;
      color: #ffffff;
      margin-right: 10px;
      cursor: pointer;
      position: relative;
      &:hover {
        .cate-box {
          visibility: visible;
        }
      }
    }

    .menu-item {
      cursor: pointer;
      width: 134px;
      height: 40px;
      font-size: 18px;
      color: #444444;
    }
  }

  .cate-box {
    position: absolute;
    height: 474px;
    border: 2px solid var(--color-primary);
    background: #ffffff;
    bottom: -479px;
    left: -2px;
    visibility: hidden;
    z-index: 99;

    .left {
      width: 210px;
      height: 474px;
      padding-top: 10px;
      position: relative;
      box-sizing: border-box;
      &:hover .right {
        display: block;
        display: flex;
      }

      .cate-list {
        height: 460px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .title {
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        margin-bottom: 2px;
      }

      .subtitle {
        font-size: 12px;
        color: #999999;
        line-height: 16px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
          color: #eb5c20;
        }
      }

      .item {
        width: 210px;
        padding-left: 20px;
        padding-top: 6px;
        box-sizing: border-box;

        &:hover {
          background: rgba(153, 153, 153, 0.2);
        }
      }
    }

    .right {
      right: -770px;
      top: -2px;
      display: none;
      position: absolute;
      border: 2px solid var(--color-primary);
      box-sizing: border-box;
      width: 770px;
      height: 478px;
      background: #ffffff;
      box-shadow: -1px 0px 4px 0px rgba(153, 153, 153, 0.5);

      .title {
        font-weight: bold;
        font-size: 14px;
        color: var(--color-primary);
        line-height: 19px;
        margin-bottom: 18px;
      }

      .sub-item {
        width: 71px;
        height: 100px;
        font-size: 14px;
        color: #444444;
        line-height: 19px;
        text-align: left;
        margin-bottom: 8px;
        margin-top: 10px;
        display: inline-flex;
        flex-direction: column;
        &:hover {
          color: var(--color-primary);
        }
        img {
          width: 52px;
          height: 52px;
        }
        p {
          margin-top: 5px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0;
          text-align: center;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .list {
        width: 620px;
        height: 478px;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
